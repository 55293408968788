
<div class="header-image-wrapper" [dir]="(settings.rtl) ? 'rtl' : 'ltr'" [class.fullscreen]="fullscreen" [ngClass]="'toolbar-'+settings.toolbar">
    <div class="bg" [style.background-image]="bgImage" [class.bg-anime]="bgImageAnimate"></div>
    <div class="mask"></div>
    <div class="header-image-content" fxLayout="column" fxLayoutAlign="center center" [class.offset-bottom]="contentOffsetToTop" [class.home-page]="isHomePage" [style.min-height.px]="contentMinHeight">
        <h2 class="big-header" style="margin-top: 125px;">Louer n'importe quelle voiture, le modèle de votre choix</h2>
        <h1 class="small-header">Louer un véhicule {{modelVoiture}}</h1>
        <div class="header-image-content" fxLayout="column" fxLayoutAlign="center center">
            <app-header-search [modelCar]="modelVoiture"></app-header-search>        
        </div>      
    </div>
</div>

<div class="section default mt-3" style="background-color: #fafafa;">
    <div class="px-3">
        <div fxLayout="column" fxLayoutAlign="center center">
            <div class="theme-container">
                <div class="swiper-container h-100 carousel-outer" [swiper]="config" *ngIf="cars.length !=0; else noCar">
                    <div fxLayout="column" fxLayoutAlign="center center" class="m-2">
                        <div fxFlex="100" fxFlex.gt-sm="50" fxLayoutAlign="start start" style="font-weight:bolder">Nos Modèles de Véhicule {{modelVoiture}}</div>
                        <div fxFlex="100" fxFlex.gt-sm="50" fxLayoutAlign="end end">
                            <button class="prop-prev" mat-icon-button color="primary">
                                <mat-icon>chevron_left</mat-icon>
                            </button>
                            <button class="prop-next" mat-icon-button color="primary">
                                <mat-icon>chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
          
                    <div class="swiper-wrapper h-100 m-2" >
                        <div class="swiper-slide" *ngFor="let car of cars">
                            <mat-card class="example-card image-card">
                                <img mat-card-image [src]="getHost.getHttpsImage() + car.title_image" alt="Photo of a Shiba Inu">
                                <div fxLayout="column" fxLayoutAlign="start stretch" class="h-100">
                                    <mat-card-content class="mb-0 descCar">
                                        <h1 class="title">{{car.vehicle_categorie}}</h1>
                                        <div>
                                            <button class="item-button" mat-flat-button  matTooltip="Nombres de place" color="secondary">{{car.number_place_limit}} <mat-icon>group</mat-icon></button>
                                            <button class="item-button" mat-flat-button matTooltip="Moteur" color="secondary">M <mat-icon>account_tree</mat-icon></button>
                                            <!-- <button class="item-button" mat-flat-button matTooltip="Energie" color="secondary">D <mat-icon>energy_savings_leaf</mat-icon></button> -->
                                            <button class="item-button" mat-flat-button matTooltip="Climatisation" color="secondary">A/C <mat-icon>severe_cold</mat-icon></button>
                                        </div>
                                        <div>
                                            <h1></h1>
                                        </div>
                                        <div class="mt-2 mb-2"> 
                                            <div class="features">
                                                <h3>{{car.vehicle_adress}}</h3>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                    <div class="sticky-footer-helper" fxFlex="grow"></div>
                                    <mat-card-actions class="m-0 p-0">
                                       
                                        <div fxLayout="row" [fxLayoutAlign]="(viewType == 'grid') ? 'space-between center' : 'end center'"
                                            class="mt-3">
                                            <span class="text-muted">Avis Client<span *ngIf="viewType == 'list'" class="px-2">: {{car.note}}</span></span>
                                            <app-rating [ratingsCount]='car.note' ratingsValue=2 [comment]='car.count_note'></app-rating>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between center" class="divider mt-1">
                        
                                            <h2 class="primary-color">
                                                {{ car.vehicle_price_location}} {{appService.getCurrency()}}
                                            </h2>
                        
                                            <div class="text-muted">
                                                <div fxLayout="row" fxLayoutAlign="space-between center" class="divider mt-1">
                                                    <a (click)="clickCar(car.id)" mat-raised-button color="primary" class="transform-none"
                                                        id="btnreserver">Reserver
                                                        <mat-icon>no_crash</mat-icon>
                                                    </a>
                        
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card-actions>
                                </div>                  
                              </mat-card>
                        </div>
                    </div>
                   
                    <div fxLayout="column" fxLayoutAlign="center center" class="m-4">
                        <a mat-raised-button color="primary" class="a-button" (click)="getcar()">Louer un véhicule {{modelVoiture}}</a>
                    </div>
                </div>
                <ng-template #noCar>
                    <h1 style="text-align:center">Désolé !!!</h1>
                    <h3 style="text-align:center">Nous n'avons aucun model de {{modelVoiture}} disponible pour le moment !</h3>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<div class="section default mt-3" style="background-color: #f3f2f2;">
    <app-about-umexi></app-about-umexi>
</div>
