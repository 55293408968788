<footer class="no-responsive">
    <div class="section mt-2">
        <div class="px-3">
            <div class="theme-container">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" class="copyright py-2">
                    <div fxFlex="100" fxFlex.gt-sm="25" fxFlex.sm="100" fxLayoutAlign="center" class="header-footer" style="max-width: 15%;">
                        <!-- <a class="logo" routerLink="/" style="float:left"> -->
                        <a class="logo" routerLink="/">
                            <!-- <app-logo [size]="true"></app-logo> -->
                            <img [src]="getHost.getHttpsImage()+'images/logo-umex-107.png'" width="110px"
                                alt="location de vehicule Abidjan">
                        </a>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="75" fxFlex.sm="100" class="header-footer">
                        <!-- <span style="text-align: justify; float:right; margin: 30px;"> -->
                        <p style="font-size:14px;text-align: justify; margin-top:20px"> 
                            Umex est une plateforme sécurisée de location de voitures
                            et d’utilitaires basée sur la consommation collaborative.
                            Le concept propose un usage plus simple et plus flexible de la voiture avec
                            la possibilité pour l’utilisateur locataire de louer un véhicule  en
                            quelques clics,à chaque instant, partout en Cote d'ivoire.
                            Société enregistrée au R.C sous le numéro  <strong style="color: #fba400;"> DKR 2020 B 14769
                            </strong> le 13/08/2020.
                        </p>
                    </div>  
                </div>
            </div>
        </div>
    </div>
    <div class="px-3">
        <div class="theme-container">

            <div class="p-5 content border-lighter">
                <div fxLayout="row wrap" fxLayoutAlign="space-between">
                    <div fxFlex="100" fxFlex.gt-sm="17.5" fxFlex.sm="35">
                        <h3 class="primary">Umex</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25" alt="location de vehicule Abidjan">
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/professionnels'">Professionnels</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/a-propos-de-nous'">A propos</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/reglements'">Règlements</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'blog/actualités'">Actualités</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'blog'">Blog</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/temoignages'">Témoignages</a>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="17.5" fxFlex.sm="35">
                        <h3 class="primary">Liens Utils</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25" alt="location de vehicule Abidjan">
                        <br /><a routerLink="/create-utilisateurs/">S'inscrire</a>
                        <br /><a routerLink="/login/">Se connecter</a>
                        <br /><a routerLink="/compte-utilisateur/mes-vehicules/liste-vehicule/">Louez une voiture</a>
                        <br /><a routerLink="/vehicules-disponibles/">Reserver une voiture</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/trouverdeaide'">Trouver de aide</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/systeme-paiement-umex'">Paiements</a>
                    </div>

                    <div fxFlex="100" fxFlex.gt-sm="17.5" fxFlex.sm="35">
                        <h3 class="primary">Explorer</h3>
                        <img  [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">
                        <br><a [href]="getHost.getHttpsUrl()+'aide/reserver-une-voiture'">Reserver une voiture</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/mariage'">Mariage</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/voyage-en-famille'">Voyage en famille</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/demenagement'">Demenagement</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/vacance'">Vacance</a>
                    </div>

                    <div fxFlex="100" fxFlex.gt-sm="17.5" fxFlex.sm="35">
                        <h3 class="primary">Decouvrir</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">

                        <br /><a [href]="getHost.getHttpsUrl()+'aide/astuces'">Astuces</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/assurance'">Assurance</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/entreprises'">Entreprise</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/telechager'">Telechager Appli mobile</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/assistance'">Assistance depannage</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/imprimer'">Imprimer ma facture</a>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="40">
                        <app-social-icons [iconSize]="'lg'" fxLayout="row" fxLayoutAlign="start center" class="desc" style="color: white; margin-bottom:7px"></app-social-icons>
                        <p class="social-media" fxLayout="row" fxLayoutAlign="start center">     
                            <mat-icon color="primary" style="color: #fba400;">call</mat-icon>                       
                            <span class="mx-2"> (+225) 77 516 89 18</span>  
                            <mat-icon color="primary" style="color: #fba400;">mail_outline</mat-icon>                        
                            <span class="mx-2">  contact@umexci.com</span>                           
                        </p>
                        <p  class="social-media" fxLayout="row" fxLayoutAlign="start center"> 
                            <mat-icon color="primary" style="color: #fba400;">location_on</mat-icon>                                                
                            <span class="mx-2"> Arafat 2 villa N°794, Abidjan, Cote d'ivoire</span>
                        </p>
                        <p  class="social-media" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon color="primary" style="color: #fba400;">schedule</mat-icon>
                            <span class="mx-2">Lun - Ven / 8H30 - 12H30 & 13H30 - 18H30</span>
                        </p>
        
                    </div>

                </div>

            </div>

            <div class="p-5 content border-lighter" style="margin-top: 35px;">
                <div fxLayout="row wrap" fxLayoutAlign="space-between" >
                    <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="35">
                        <h3 class="primary">Type de vehicule</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/citadine'">Citadine</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/4x4'">4x4</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/suv'">Suv</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/utilitaire'">Utilitaire</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/fourgon'">Fourgon</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/berline'">Berline</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/luxe'">Luxe</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/monospace'">Monospace</a>
                    </div>

                    <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="35">
                        <h3 class="primary ">Marque de voiture</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/toyota'">Toyota</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/mercedes'">Mercedes</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/audi'">Audi</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/renault'">Renault</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/honda'">Honda</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/acura'">Acura</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/suziki'">Suziki</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/bmw'">Bmw</a>
                    </div>

                    <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="35">
                        <h3 class="primary">Les lieux</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">

                        <br /><a [href]="getHost.getHttpsUrl()+'aide/abidjan'">location voiture abidjan</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/bouake'">location voiture Bouaké</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/korhogo'">location voiture Korhogo</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/daloa'">location voiture Daloa</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/sanpedro'">location voiture San-Pédro</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/anyama'">location voiture Anyama</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/yamoussoukro'">location voiture Yamoussoukro</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/divo'">location voiture Divo</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/gagnoa'">location voiture Gagnoa</a>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="35">
                        <h3 class="primary">Hôtes</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">
                        <br /> <a [href]="getHost.getHttpsUrl()+'aide/locataire-comment-ca-marche'">Locataire, comment
                            ça marche?</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/proprietaire-comment-ca-marche'">Proprietaire,
                            comment ça marche?</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/outils-des-hotes'">Les outils pour les hôtes</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/assurance-et-protection'">Assurance et
                            protection</a>
                        <br>
                        <h3 class="primary ">Partenariats</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">
                        <p>Pour un partenariat veuillez nous ecrire au partenariat@umexci.com</p>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="35">
                        
                        <h3 class="primary">Paiements Securisés</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/moyens-de-paiements'">
                            <img [src]="getHost.getHttpsImage()+'images/systeme-paiement-umex.jpg'"
                                alt="systeme de Paiements">
                        </a>
                    </div>
                </div>
            </div>

            <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright py-2">

                <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                    <mat-icon color="primary">location_on</mat-icon>
                    <span class="mx-2">Arafat 2 villa N°794, Abidjan, Cote d'ivoire</span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <mat-icon color="primary">call</mat-icon>
                    <span class="mx-2">(+225) 77 516 89 18</span>
                </p>

                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <mat-icon color="primary">mail_outline</mat-icon>
                    <span class="mx-2">contact@umexci.com</span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <mat-icon color="primary">schedule</mat-icon>
                    <span class="mx-2">Lun - Ven / 8H30 - 12H30 & 13H30 - 18H30</span>
                </p>
                <app-social-icons [iconSize]="'lg'" fxLayout="row" fxLayoutAlign="start center"
                    class="desc"></app-social-icons>
            </div> -->

            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright py-2">
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/cgu'">CGU</a></span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/charte-de-transparence'">Charte de transparence</a></span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/mentions-legales'">Mentions Légales </a></span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/politique-de-confidentialite'">Politique de
                            Confidentialité</a> </span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/cookies'">Cookies</a></span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/charte-de-transparence'">Charte de transparence</a></span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/conditions-de-service'">Conditions de service</a></span>
                </p>
                <!-- <p ngClass.xs="mt-1">Copyright © {{year}} All Rights Reserved</p> -->
                <p>Copyright © {{year}} All Rights Reserved<a mat-button href="https://www.jainliconsulting.com/"
                        target="_blank">Jainli Consulting </a></p>
            </div>
        </div>
    </div>
</footer>
<footer class="responsive">
    <div class="section mt-2">
        <div class="px-3">
            <div class="theme-container">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" class="copyright py-2">
                    <div fxFlex="100" fxLayoutAlign="center" class="header-footer" style="max-width: 15%;">
                        <!-- <a class="logo" routerLink="/" style="float:left"> -->
                        <a>
                            <!-- <app-logo [size]="true"></app-logo> -->
                            <img [src]="getHost.getHttpsImage()+'images/logo-umex-107.png'" width="110px"
                                alt="location de vehicule Abidjan">
                        </a>
                    </div>
                    
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" class="copyright py-2">
                    <div fxFlex="100" fxLayoutAlign="center" class="header-footer">
                        <!-- <span style="text-align: justify; float:right; margin: 30px;"> -->
                        <p style="font-size:14px;text-align: justify; margin-top:20px"> 
                            Umex est une plateforme sécurisée de location de voitures
                            et d’utilitaires basée sur la consommation collaborative.
                            Le concept propose un usage plus simple et plus flexible de la voiture avec
                            la possibilité pour l’utilisateur locataire de louer un véhicule  en
                            quelques clics,à chaque instant, partout en Cote d'ivoire.
                            Société enregistrée au R.C sous le numéro  <strong style="color: #fba400;"> DKR 2020 B 14769
                            </strong> le 13/08/2020.
                        </p>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" class="copyright py-2">
                    <div fxFlex="100" >
                        <app-social-icons [iconSize]="'lg'" fxLayout="row" fxLayoutAlign="center center" class="desc" style="color: white; margin-bottom:7px"></app-social-icons>
                        <p class="social-media" fxLayout="row" fxLayoutAlign="center">     
                            <mat-icon color="primary" style="color: #fba400;">call</mat-icon>                       
                            <span class="mx-2"> (+225) 77 516 89 18</span>                           
                        </p>
                        <p class="social-media" fxLayout="row" fxLayoutAlign="center">     
                            <mat-icon color="primary" style="color: #fba400;">mail_outline</mat-icon>                        
                            <span class="mx-2">  contact@umexci.com</span>                           
                        </p>
                        <p  class="social-media" fxLayout="row" fxLayoutAlign="center"> 
                            <mat-icon color="primary" style="color: #fba400;">location_on</mat-icon>                                                
                            <span class="mx-2"> Arafat 2 villa N°794, Abidjan, Cote d'ivoire</span>
                        </p>
                        <p  class="social-media" fxLayout="row" fxLayoutAlign="center">
                            <mat-icon color="primary" style="color: #fba400;">schedule</mat-icon>
                            <span class="mx-2">Lun - Ven / 8H30 - 12H30 & 13H30 - 18H30</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px-3">
        <div class="theme-container">
        <mat-accordion>
            <mat-expansion-panel (opened)="false">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    Umex
                </mat-panel-title>
                </mat-expansion-panel-header>
                <a [href]="getHost.getHttpsUrl()+'aide/professionnels'">Professionnels</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/a-propos-de-nous'">A propos</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/reglements'">Règlements</a>
                <br /><a [href]="getHost.getHttpsUrl()+'blog/actualités'">Actualités</a>
                <br /><a [href]="getHost.getHttpsUrl()+'blog'">Blog</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/temoignages'">Témoignages</a>
            </mat-expansion-panel>
            <mat-divider></mat-divider>
            <mat-expansion-panel (opened)="false">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    Liens Utils
                </mat-panel-title>
                </mat-expansion-panel-header>
                <a routerLink="/create-utilisateurs/">S'inscrire</a>
                <br /><a routerLink="/login/">Se connecter</a>
                <br /><a routerLink="/compte-utilisateur/mes-vehicules/liste-vehicule/">Louez une voiture</a>
                <br /><a routerLink="/vehicules-disponibles/">Reserver une voiture</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/trouverdeaide'">Trouver de aide</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/systeme-paiement-umex'">Paiements</a>
            </mat-expansion-panel>
            <mat-divider></mat-divider>
            <mat-expansion-panel (opened)="false">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    Explorer
                </mat-panel-title>
                </mat-expansion-panel-header>
                <a [href]="getHost.getHttpsUrl()+'aide/reserver-une-voiture'">Reserver une voiture</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/mariage'">Mariage</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/voyage-en-famille'">Voyage en famille</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/demenagement'">Demenagement</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/vacance'">Vacance</a>
            </mat-expansion-panel><mat-divider></mat-divider>
            <mat-expansion-panel (opened)="false">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    Decouvrir
                </mat-panel-title>
                </mat-expansion-panel-header>
                <a [href]="getHost.getHttpsUrl()+'aide/astuces'">Astuces</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/assurance'">Assurance</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/entreprises'">Entreprise</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/telechager'">Telechager Appli mobile</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/assistance'">Assistance depannage</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/imprimer'">Imprimer ma facture</a>
            </mat-expansion-panel><mat-divider></mat-divider>
            <mat-expansion-panel (opened)="false">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    Type de vehicule
                </mat-panel-title>
                </mat-expansion-panel-header>
                <a [href]="getHost.getHttpsUrl()+'aide/citadine'">Citadine</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/4x4'">4x4</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/suv'">Suv</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/utilitaire'">Utilitaire</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/fourgon'">Fourgon</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/berline'">Berline</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/luxe'">Luxe</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/monospace'">Monospace</a>
            </mat-expansion-panel><mat-divider></mat-divider>
            <mat-expansion-panel (opened)="false">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    Marque de voiture
                </mat-panel-title>
                </mat-expansion-panel-header>
                <a [href]="getHost.getHttpsUrl()+'aide/toyota'">Toyota</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/mercedes'">Mercedes</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/audi'">Audi</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/renault'">Renault</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/honda'">Honda</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/acura'">Acura</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/suziki'">Suziki</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/bmw'">Bmw</a>
            </mat-expansion-panel><mat-divider></mat-divider>
            <mat-expansion-panel (opened)="false">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    Les lieux
                </mat-panel-title>
                </mat-expansion-panel-header>
                <a [href]="getHost.getHttpsUrl()+'aide/abidjan'">location voiture abidjan</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/bouake'">location voiture Bouaké</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/korhogo'">location voiture Korhogo</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/daloa'">location voiture Daloa</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/sanpedro'">location voiture San-Pédro</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/anyama'">location voiture Anyama</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/yamoussoukro'">location voiture Yamoussoukro</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/divo'">location voiture Divo</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/gagnoa'">location voiture Gagnoa</a>
            </mat-expansion-panel><mat-divider></mat-divider>
            <mat-expansion-panel (opened)="false">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    Hôtes
                </mat-panel-title>
                </mat-expansion-panel-header>
                <a [href]="getHost.getHttpsUrl()+'aide/locataire-comment-ca-marche'">Locataire, comment ça marche?</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/proprietaire-comment-ca-marche'">Proprietaire, comment ça marche?</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/outils-des-hotes'">Les outils pour les hôtes</a>
                <br /><a [href]="getHost.getHttpsUrl()+'aide/assurance-et-protection'">Assurance et protection</a>
            </mat-expansion-panel><mat-divider></mat-divider>
            <mat-expansion-panel (opened)="false">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    Partenariats
                </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Pour un partenariat veuillez nous ecrire au partenariat@umexci.com</p>
            </mat-expansion-panel><mat-divider></mat-divider>
            <mat-expansion-panel (opened)="false">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    Paiements Securisés
                </mat-panel-title>
                </mat-expansion-panel-header>
                <a [href]="getHost.getHttpsUrl()+'aide/moyens-de-paiements'">
                    <img [src]="getHost.getHttpsImage()+'images/systeme-paiement-umex.jpg'"
                        alt="systeme de Paiements">
                </a>
            </mat-expansion-panel>
        </mat-accordion>

            <!-- <div class="p-5 content border-lighter">
                <div fxLayout="row wrap" fxLayoutAlign="space-between">
                    <div fxFlex="100" fxFlex.gt-sm="17.5" fxFlex.sm="35">
                        <h3 class="primary">Umex</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25" alt="location de vehicule Abidjan">
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/professionnels'">Professionnels</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/a-propos-de-nous'">A propos</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/reglements'">Règlements</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'blog/actualités'">Actualités</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'blog'">Blog</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/temoignages'">Témoignages</a>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="17.5" fxFlex.sm="35">
                        <h3 class="primary">Liens Utils</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25" alt="location de vehicule Abidjan">
                        <br /><a routerLink="/create-utilisateurs/">S'inscrire</a>
                        <br /><a routerLink="/login/">Se connecter</a>
                        <br /><a routerLink="/compte-utilisateur/mes-vehicules/liste-vehicule/">Louez une voiture</a>
                        <br /><a routerLink="/vehicules-disponibles/">Reserver une voiture</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/trouverdeaide'">Trouver de aide</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/systeme-paiement-umex'">Paiements</a>
                    </div>

                    <div fxFlex="100" fxFlex.gt-sm="17.5" fxFlex.sm="35">
                        <h3 class="primary">Explorer</h3>
                        <img  [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">
                        <br><a [href]="getHost.getHttpsUrl()+'aide/reserver-une-voiture'">Reserver une voiture</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/mariage'">Mariage</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/voyage-en-famille'">Voyage en famille</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/demenagement'">Demenagement</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/vacance'">Vacance</a>
                    </div>

                    <div fxFlex="100" fxFlex.gt-sm="17.5" fxFlex.sm="35">
                        <h3 class="primary">Decouvrir</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">

                        <br /><a [href]="getHost.getHttpsUrl()+'aide/astuces'">Astuces</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/assurance'">Assurance</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/entreprises'">Entreprise</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/telechager'">Telechager Appli mobile</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/assistance'">Assistance depannage</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/imprimer'">Imprimer ma facture</a>
                    </div>
                </div>
            </div>

            <div class="p-5 content border-lighter" style="margin-top: 35px;">
                <div fxLayout="row wrap" fxLayoutAlign="space-between" >
                    <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="35">
                        <h3 class="primary">Type de vehicule</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/citadine'">Citadine</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/4x4'">4x4</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/suv'">Suv</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/utilitaire'">Utilitaire</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/fourgon'">Fourgon</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/berline'">Berline</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/luxe'">Luxe</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/monospace'">Monospace</a>
                    </div>

                    <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="35">
                        <h3 class="primary ">Marque de voiture</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/toyota'">Toyota</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/mercedes'">Mercedes</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/audi'">Audi</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/renault'">Renault</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/honda'">Honda</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/acura'">Acura</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/suziki'">Suziki</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/bmw'">Bmw</a>
                    </div>

                    <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="35">
                        <h3 class="primary">Les lieux</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">

                        <br /><a [href]="getHost.getHttpsUrl()+'aide/abidjan'">location voiture abidjan</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/bouake'">location voiture Bouaké</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/korhogo'">location voiture Korhogo</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/daloa'">location voiture Daloa</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/sanpedro'">location voiture San-Pédro</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/anyama'">location voiture Anyama</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/yamoussoukro'">location voiture Yamoussoukro</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/divo'">location voiture Divo</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/gagnoa'">location voiture Gagnoa</a>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="35">
                        <h3 class="primary">Hôtes</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">
                        <br /> <a [href]="getHost.getHttpsUrl()+'aide/locataire-comment-ca-marche'">Locataire, comment
                            ça marche?</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/proprietaire-comment-ca-marche'">Proprietaire,
                            comment ça marche?</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/outils-des-hotes'">Les outils pour les hôtes</a>
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/assurance-et-protection'">Assurance et
                            protection</a>
                        <br>
                        <h3 class="primary ">Partenariats</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">
                        <p>Pour un partenariat veuillez nous ecrire au partenariat@umexci.com</p>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="35">
                        
                        <h3 class="primary">Paiements Securisés</h3>
                        <img [src]="getHost.getHttpsImage()+'images/categories/trait.jpg'" width="25"
                            alt="location de vehicule Abidjan">
                        <br /><a [href]="getHost.getHttpsUrl()+'aide/moyens-de-paiements'">
                            <img [src]="getHost.getHttpsImage()+'images/systeme-paiement-umex.jpg'"
                                alt="systeme de Paiements">
                        </a>
                    </div>
                </div>
            </div> -->

            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright py-2">
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/cgu'">CGU</a></span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/charte-de-transparence'">Charte de transparence</a></span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/mentions-legales'">Mentions Légales </a></span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/politique-de-confidentialite'">Politique de
                            Confidentialité</a> </span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/cookies'">Cookies</a></span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/charte-de-transparence'">Charte de transparence</a></span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2"><a [href]="getHost.getHttpsUrl()+'aide/conditions-de-service'">Conditions de service</a></span>
                </p>
                <!-- <p ngClass.xs="mt-1">Copyright © {{year}} All Rights Reserved</p> -->
                <p>Copyright © {{year}} All Rights Reserved<a mat-button href="https://www.jainliconsulting.com/"
                        target="_blank">Jainli Consulting </a></p>
            </div>
        </div>
    </div>
</footer>